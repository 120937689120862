// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import I18n from "i18n-js";
import jaTranslations from "locales/ja.json";
import enTranslations from "locales/en.json";
window.I18n = I18n;

// 現在のロケールに基づいて翻訳を設定する関数
function setLocale(locale) {
  switch (locale) {
    case "en":
      I18n.translations.en = enTranslations.en;
      I18n.locale = "en";
      break;
    case "ja":
      I18n.translations.ja = jaTranslations.ja;
      I18n.locale = "ja";
      break;
    default:
      I18n.translations.en = enTranslations.en;
      I18n.locale = "en";
  }
}
window.setLocale = setLocale;

Rails.start()
ActiveStorage.start()

window.showLoading = (
  message
) => {
  const lottie = document.getElementById("lottie");
  if (!lottie) {
    return;
  }

  const loading = document.getElementsByClassName("loading-overlay")[0];
  if (loading) {
    loading.classList.add("is-visible");
  }
  lottie.style = "display: block;";

  const text = document.getElementById("lottie-text");
  if (text) {
    text.remove();
  }

  if (message) {
    const text = document.createElement("p");
    text.id = "lottie-text";
    text.textContent = message;
    text.style = "text-align: center";
    document.getElementById("lottie").append(text);
  }
};
window.hideLoading = () => {
  const loading = document.getElementsByClassName("loading-overlay")[0]
  if (loading) {
    loading.classList.remove("is-visible")
  }
}

window.showMessage = (message, callback) => {
  const loading = document.getElementsByClassName("loading-overlay")[0]
  if (loading) {
    loading.classList.add("is-visible")
  }
  const lottie = document.getElementById("lottie")
  lottie.style = 'display: none;'

  const prevText = document.getElementById("overlay-text")
  if (prevText != undefined) {
    prevText.remove()
  }

  const text = document.createElement("p")
  text.id = "overlay-text"
  text.innerHTML = message
  text.style = "position: absolute; top: 50%; left: 50%; width: 400px; transform: translate(-50%, -50%); text-align: center"
  loading.append(text)
  if (callback != undefined) {
    const button = document.createElement("button")
    button.textContent = "戻る"
    button.addEventListener("click", callback)
    button.style = "position: absolute; top: 50%; left: 50%; margin-top: 40px; transform: translate(-50%, -50%); "
    loading.append(button)
  }
}

window.bindLoading = () => {
  for (let element of document.getElementsByClassName("with-loading")) {
    element.addEventListener("click", (e) => {
      showLoading()
    })
  }
  for (let element of document.getElementsByTagName("form")) {
    element.addEventListener("submit", (e) => {
      showLoading()
    })
  }
  // 全部のリンクに付ける場合はこれ
  // for (let element of document.getElementsByTagName("a")) {
  //   if (element.target == "_blank") { continue }
  //   if (element.hash != "") { continue }
  //   element.addEventListener("click", (e) => {
  //     showLoading()
  //   })
  // }
}


window.onpagehide = event => {
  if (event.persisted) {
    /* このページは破棄されないので、後で再利用することができます */
    hideLoading()
  }
}
window.unload = event => {
  hideLoading()
}

document.addEventListener("DOMContentLoaded", () => {
  // データ属性からロケールを読み取る
  const locale = document.body.dataset.locale;
  console.log("locale: ", locale)
  setLocale(locale || "ja"); // デフォルト値を設定
});
